import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

const toastError = (err, title) => {
	const errorMsg = err.response?.data?.message
	if (errorMsg) {
		if (i18n.exists(`backendErrors.${errorMsg}`)) {
			toast.error(i18n.t(`backendErrors.${errorMsg}`), {
				toastId: errorMsg,
			});
		} else {
			toast.error(errorMsg, {
				toastId: errorMsg,
			});
		}
	} else {
		toast.error(title);
	}
};

export default toastError;

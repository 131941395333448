import React, { Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import '../style-rtl.css'

import LoggedInLayout from "../layout";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import RouteCustom from "./Route";
import LoaderRoute from "../components/LoaderRoute";
import Login from "../pages/Login/"
import Signup from "../pages/Signup/"
const Dashboard = React.lazy(() => import("../pages/Dashboard/"));
const Tickets = React.lazy(() => import("../pages/Tickets/"));
const Connections = React.lazy(() => import("../pages/Connections/"));
const Settings = React.lazy(() => import("../pages/Settings/"));
const Users = React.lazy(() => import("../pages/Users"));
const Contacts = React.lazy(() => import("../pages/Contacts/"));
const QuickAnswers = React.lazy(() => import("../pages/QuickAnswers/"));
const Queues = React.lazy(() => import("../pages/Queues/"));

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <RouteCustom exact path="/login" component={Login} />
          <RouteCustom exact path="/signup" component={Signup} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Suspense fallback={<LoaderRoute />}>
                <RouteCustom exact path="/" component={Dashboard} isPrivate />
                <RouteCustom
                  exact
                  path="/tickets/:ticketId?"
                  component={Tickets}
                  isPrivate
                />
                <RouteCustom
                  exact
                  path="/connections"
                  component={Connections}
                  isPrivate
                />
                <RouteCustom exact path="/contacts" component={Contacts} isPrivate />
                <RouteCustom exact path="/users" component={Users} isPrivate />
                <RouteCustom
                  exact
                  path="/quickAnswers"
                  component={QuickAnswers}
                  isPrivate
                />
                <RouteCustom exact path="/Settings" component={Settings} isPrivate />
                <RouteCustom exact path="/Queues" component={Queues} isPrivate />
              </Suspense>
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer rtl autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;

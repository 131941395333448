import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Link,
} from '@mui/material';

import { LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';

import { i18n } from '../../translate/i18n';

import { AuthContext } from '../../context/Auth/AuthContext';
import { getEmailDemo, getIsDemo, getPasswordDemo } from '../../config';

//? start custom css mui

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#c10015 !important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link_login: {
    marginTop: '.5rem',
  },
  backgroundIMG: {
    backgroundImage: 'url(./cover.png)',
    backgroundRepeat: 'repeat',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  page_Login: {
    backgroundColor: '#FFF',
    boxShadow: '0 0 10px .4px',
    borderRadius: '1rem',
  },
}));
// ?end custom css mui

const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState(
    getIsDemo()
      ? { email: getEmailDemo(), password: getPasswordDemo() }
      : { email: '', password: '' },
  );
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    console.log('user', user);
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <div className={classes.backgroundIMG}>
      <Container className={classes.page_Login} component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            {i18n.t('login.title')}
          </Typography>
          <form className={classes.form} noValidate onSubmit={handlSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              className={classes.direction}
              fullWidth
              id="email"
              label={i18n.t('login.form.email')}
              name="email"
              value={user.email}
              onChange={handleChangeInput}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={i18n.t('login.form.password')}
              id="password"
              value={user.password}
              onChange={handleChangeInput}
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((e) => !e)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {i18n.t('login.buttons.submit')}
            </Button>
          </form>
        </div>
        <Box mt={2}>{/* <Copyright /> */}</Box>
      </Container>
    </div>
  );
};

export default Login;

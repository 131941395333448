import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import { Badge } from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";


function ListItemLink(props) {
  const { icon, primary, valueItem, addColorItemSelected, itemSelected, to, className } = props;
  const listItemStyle = {
    backgroundColor: itemSelected === valueItem ? '#2576d2 ' : 'inherit',
    color: itemSelected === valueItem ? '#fff ' : 'inherit',

  };
  const listItemClassName = `item__links ${className} ${itemSelected === valueItem ? 'item__links--selected' : ''}`;


  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem
        selected={itemSelected === valueItem}
        style={listItemStyle}
        valueitem={valueItem}
        onClick={e => addColorItemSelected(e.target.getAttribute("valueitem"))}
        button component={renderLink}
        className={listItemClassName}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [itemSelected, setItemSelected] = useState("dashboard") //for selected color Item
  const [connectionWarning, setConnectionWarning] = useState(false);
  useEffect(() => {
    if (document.location.pathname === '/') {
      setItemSelected("dashboard")
    } else if (document.location.pathname === "/tickets") {
      setItemSelected("tickets")
    }
  })
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const addColorItemSelected = (idItemLink) => {
    setItemSelected(idItemLink)
  }

  return (
    <div onClick={drawerClose}>
      <div className="isOnline__user" style={connectionWarning ? { backgroundColor: "#e62e2d" } : { backgroundColor: "#499b62" }}></div>
      <ListItemLink
        to="/"
        addColorItemSelected={addColorItemSelected}
        valueItem="dashboard"
        itemSelected={itemSelected}
        primary={i18n.t("mainDrawer.listItems.dashboard")}
        icon={<Badge color="primary">
          <DashboardOutlinedIcon />
        </Badge>
        }
      />
      <ListItemLink
        to="/connections"
        itemSelected={itemSelected}
        addColorItemSelected={addColorItemSelected}
        valueItem="connections"
        primary={i18n.t("mainDrawer.listItems.connections")}
        icon={
          <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
            <SyncAltIcon />

          </Badge>

        }
      >

      </ListItemLink>
      <ListItemLink
        valueItem="tickets"
        itemSelected={itemSelected}
        addColorItemSelected={addColorItemSelected}
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon />}
      />

      <ListItemLink
        to="/contacts"
        itemSelected={itemSelected}
        addColorItemSelected={addColorItemSelected}
        valueItem="contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactPhoneOutlinedIcon />}


      />

      <ListItemLink
        valueItem="quickAnswers"
        itemSelected={itemSelected}
        to="/quickAnswers"
        addColorItemSelected={addColorItemSelected}
        primary={i18n.t("mainDrawer.listItems.quickAnswers")}
        icon={<QuestionAnswerOutlinedIcon />}
      />
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            <ListSubheader inset>
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>
            <ListItemLink
              to="/users"
              itemSelected={itemSelected}
              addColorItemSelected={addColorItemSelected}
              valueItem="users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<PeopleAltOutlinedIcon />}
            />
            <ListItemLink
              valueItem="queues"
              itemSelected={itemSelected}

              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<AccountTreeOutlinedIcon />}
              addColorItemSelected={addColorItemSelected}

            />
            <ListItemLink
              itemSelected={itemSelected}

              valueItem="settings"
              addColorItemSelected={addColorItemSelected}

              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlinedIcon />}
            />
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;

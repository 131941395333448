import React from "react";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { CircularProgress } from "@mui/material";

const LoaderRoute = () => {


    return (
        <div className="loadingRoutes">
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
            <Box sx={{ display: 'flex' }} >
                <CircularProgress size="1.4rem" />
            </Box>
        </div>
    );
}
export default LoaderRoute;
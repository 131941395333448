import React, { useState } from "react";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";

import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Grid,
	Box,
	Typography,
	Container,
	InputAdornment,
	IconButton,
	Link
} from '@mui/material';

import { LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material';

import { makeStyles } from "@mui/styles";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
// start custom css mui

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: "23px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: "#c10015 !important",
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: "1rem 0rem !important",
		padding: ".5rem 0 !important"
	},
	link_login: {
		marginTop: ".5rem",
		justifyContent: "end"
	},
	backgroundIMG: {
		backgroundImage: 'url(./cover.png)',
		position: "absolute",
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundRepeat: "repeat",

	}
}));
// end custom css mui
// start filter input
const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "حداقل 2 کارکتر")
		.max(50, "حداکثر 50 کراکتر")
		.required("اجباری"),
	password: Yup.string().min(5, "حداقل 2 کارکتر").max(50, "حداکثر 50 کراکتر"),
	email: Yup.string().email("ایمیل معتبر وارد کنید").required("اجباری"),
});
// end filter input

const SignUp = () => {
	const classes = useStyles();
	const history = useHistory();

	const initialState = { name: "", email: "", password: "" };
	const [showPassword, setShowPassword] = useState(false);
	const [user] = useState(initialState);

	const handleSignUp = async values => {
		try {
			await api.post("/auth/signup", values);
			toast.success(i18n.t("signup.toasts.success"));
			history.push("/login");
		} catch (err) {
			toastError(err, i18n.t("signup.toasts.error"));
		}
	};

	return (
		<div className={classes.backgroundIMG}>
			<Container component="main" maxWidth="xs" style={{ backgroundColor: "#FFF", boxShadow: "0 0 10px .4px", borderRadius: "1rem" }}>
				<CssBaseline />
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlined />
					</Avatar>
					<Typography component="h1" variant="h5">
						{i18n.t("signup.title")}
					</Typography>
					{/* <form className={classes.form} noValidate onSubmit={handleSignUp}> */}
					<Formik
						initialValues={user}
						enableReinitialize={true}
						validationSchema={UserSchema}
						onSubmit={(values, actions) => {
							setTimeout(() => {
								handleSignUp(values);
								actions.setSubmitting(false);
							}, 400);
						}}
					>
						{({ touched, errors, isSubmitting }) => (
							<Form className={classes.form}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Field
											as={TextField}
											autoComplete="name"
											name="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											variant="outlined"
											fullWidth
											id="name"
											label={i18n.t("signup.form.name")}
											autoFocus
										/>
									</Grid>

									<Grid item xs={12}>
										<Field
											as={TextField}
											variant="outlined"
											fullWidth
											id="email"
											label={i18n.t("signup.form.email")}
											name="email"
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
											autoComplete="email"
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											as={TextField}
											variant="outlined"
											fullWidth
											name="password"
											id="password"
											autoComplete="current-password"
											error={touched.password && Boolean(errors.password)}
											helperText={touched.password && errors.password}
											label={i18n.t("signup.form.password")}
											type={showPassword ? 'text' : 'password'}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => setShowPassword((e) => !e)}
														>
															{showPassword ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									</Grid>
								</Grid>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
								>
									{i18n.t("signup.buttons.submit")}
								</Button>
								<Grid container justifyContent="start" className={classes.link_login}>
									<Grid item>
										<Link
											href="#"
											variant="body2"
											component={RouterLink}
											to="/login"

										>
											{i18n.t("signup.buttons.login")}
										</Link>
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</div>
				<Box mt={2}>{/* <Copyright /> */}</Box>
			</Container>
		</div>

	);
};

export default SignUp;

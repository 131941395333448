import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccountCircle from '@mui/icons-material/AccountCircle';

import MainListItems from './MainListItems';
import NotificationsPopOver from '../components/NotificationsPopOver';
import UserModal from '../components/UserModal';
import { AuthContext } from '../context/Auth/AuthContext';
import BackdropLoading from '../components/BackdropLoading';
import { i18n } from '../translate/i18n';

import './index.css';
const drawerWidth = '14rem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 56px)',
    },
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    width: '100%',
    background: '#fff !important',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '.4rem 8px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    marginRight: '13rem',
    width: `calc(100% - ${drawerWidth}px)`,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    transition: 'all 1000ms ease',
    width: drawerWidth,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: 'all 1000ms ease',

    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    minHeight: '48px',
  },
  content: {
    paddingTop: '0.7rem',
    flex: 1,
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  boxShowIconMenu: {
    display: 'flex',
    flexDirection: 'column',
  },
  isShowMenu: {
    display: 'flex',
    flexDirection: 'column',
  },
  showIconMenu: {
    width: ' 30px',
    height: ' 3px',
    margin: ' 3.1px 0 !important',
    backgroundColor: ' black',
    borderRadius: ' 5',
    transition: 'all 1000ms ease',
  },
  showArrowTopLine: {
    transform: ' rotate(26deg) translateX(-2px)',
    borderRadius: '50%',
    margin: ' 4px 0 !important',
  },
  showArrowButtonLine: {
    transform: 'rotate(-28deg) translateX(-3px)',
    borderRadius: '50%',
    margin: ' 4px 0 !important',
  },
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState('permanent');
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (window.innerWidth < 600) {
      setDrawerVariant('temporary');
    } else {
      setDrawerVariant('permanent');
    }
  }, [drawerOpen]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (window.innerWidth < 600) {
      setDrawerOpen(false);
    }
  };

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose,
          ),
        }}
        open={drawerOpen}
        anchor="left"
      >
        <div
          style={
            drawerOpen
              ? { justifyContent: 'flex-end' }
              : { justifyContent: 'flex-start' }
          }
          className={classes.toolbarIcon}
        >
          <IconButton
            className={classes.isShowMenu}
            onClick={() => setDrawerOpen((prev) => !prev)}
          >
            {/* icon is show menu  */}

            <Typography
              className={clsx(
                classes.showIconMenu,
                drawerOpen ? classes.showArrowTopLine : null,
              )}
            />
            <Typography
              style={drawerOpen ? { display: 'none' } : null}
              className={classes.showIconMenu}
            />
            <Typography
              className={clsx(
                classes.showIconMenu,
                drawerOpen ? classes.showArrowButtonLine : null,
              )}
            />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems drawerClose={drawerClose} />
        </List>
        <Divider />
      </Drawer>

      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        style={{
          margin: 0,
          padding: '.2rem 0px',
          color: '#325fd7',
          fontWeight: '900',
        }}
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color={process.env.NODE_ENV === 'development' ? 'inherit' : 'primary'}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen((prev) => !prev)}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={`${classes.title} ${
              drawerOpen ? 'Full__show-brand' : 'imperfect__show-berand'
            } `}
          >
            واتساپ تیکت پلاس
          </Typography>
          {user.id && <NotificationsPopOver />}

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t('mainDrawer.appBar.user.profile')}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t('mainDrawer.appBar.user.logout')}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;
